import React, { Fragment } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import {
  Button,
} from "reactstrap";

const Home = () => {
  const {
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();

  return (
    <Fragment>
      <div className="text-center hero my-5">
        {!isAuthenticated && ( 
          <>
            <h1 className="display-1">Jua Developer Portal</h1>

            <Button
              id="qsLoginBtn"
              color="primary"
              className="mt-5 btn btn-primary btn-lg"
              onClick={() => loginWithRedirect()}
            >
              Log in
            </Button>
          </>
        )}

        {isAuthenticated && ( 
          <h1>Jua Developer Portal</h1>
        )}
      </div>
    
    </Fragment>
  )

}

export default Home;
